@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font fallback strategy */
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: local("Arial");
}

@font-face {
  font-family: "JetBrains Mono";
  font-display: swap;
  src: local("Courier New");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.font-mono {
  font-family: "JetBrains Mono", "Courier New", Courier, monospace;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(31, 41, 55, 0.5);
}

::-webkit-scrollbar-thumb {
  background: rgba(96, 165, 250, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(96, 165, 250, 0.7);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
